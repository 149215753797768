import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Guide to migrate to v2 of the Android SDK for Loyalty applications`}</h1>
    <h2>{`Update your Fidel API Android SDK integration`}</h2>
    <h3>{`If you used Jitpack to integrate the Android SDK`}</h3>
    <p>{`Replace the Fidel dependency inside your app/build.gradle by this one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-groovy"
      }}>{`implementation 'com.github.Enigmatic-Smile:android-sdk:2.2.0'
`}</code></pre>
    <h3>{`If you manually integrated the Android SDK`}</h3>
    <ol>
      <li parentName="ol">{`Replace the `}<inlineCode parentName="li">{`FidelSDK.aar`}</inlineCode>{` by the latest one. `}</li>
      <li parentName="ol">{`Remove the following dependency`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-groovy"
      }}>{`implementation 'io.card:android-sdk:5.5.1'
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Add the following dependencies:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-groovy"
      }}>{`implementation 'com.scottyab:rootbeer-lib:0.1.0'
implementation 'io.split.client:android-client:2.13.1'
implementation 'androidx.work:work-runtime-ktx:2.8.1'
`}</code></pre>
    <h2>{`Update the minSdkVersion`}</h2>
    <p>{`Previously the minSdkVersion was `}<inlineCode parentName="p">{`19`}</inlineCode>{`. You'll have to update it to `}<inlineCode parentName="p">{`21`}</inlineCode>{`, to use the Fidel API Android SDK v2.`}</p>
    <h2>{`Check the following code as your migration guide`}</h2>
    <p>{`Use the following code as a guide related to the changes that  to integrate with the Fidel API SDK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`...
import com.fidelapi.Fidel // was import com.fidel.sdk.Fidel
//remove all com.fidel.sdk imports
...

class MainActivity : AppCompatActivity() {
    override fun onCreate(savedInstanceState: Bundle?) {
        super.onCreate(savedInstanceState)
        setContentView(R.layout.activity_main)
        Fidel.programId = "Your Program ID"
        
        /*
         * We recommend storing the SDK key on your server. Please use one of your endpoints to retrieve it 
         * and then configure the sdkKey property with it.
         */
        Fidel.sdkKey = yourSdkKey // previously apiKey
        // remove Fidel.autoScan
        Fidel.allowedCountries = setOf(Country.CANADA, Country.UNITED_STATES, Country.UNITED_KINGDOM)
        Fidel.supportedCardSchemes = setOf(CardScheme.VISA, CardScheme.MASTERCARD, CardScheme.AMERICAN_EXPRESS)
        Fidel.companyName = "[Developer company]"
        Fidel.termsAndConditionsUrl = "https://fidel.uk" // termsConditionsURL
        Fidel.privacyPolicyUrl = "https://fidel.uk" // was privacyURL
        Fidel.deleteInstructions = "[Developer set delete instructions]"
        val jsonMeta = JSONObject()
        try {
            jsonMeta.put("id", "this-is-the-metadata-id")
            jsonMeta.put("customKey1", "customValue1")
            jsonMeta.put("customKey2", "customValue2")
        } catch (e: JSONException) {
            val exceptionMessage = e.localizedMessage
            if (exceptionMessage != null) {
                Log.e(FIDEL_DEBUG_TAG, exceptionMessage)
            }
        }

        Fidel.metaData = jsonMeta
        Fidel.bannerImage = ContextCompat.getDrawable(this, R.drawable.fidel_test_banner)?.toBitmap()

        val btn = findViewById<View>(R.id.btn_show) as Button
        
        //Fidel.start() replaces Fidel.present()
        btn.setOnClickListener { Fidel.start(this@MainActivity) }
        
        // replaces Fidel.setCardLinkingObserver
        Fidel.onResult = OnResultObserver { result ->
            when (result) {
                is FidelResult.Enrollment -> {
                    Log.d(FIDEL_DEBUG_TAG, "The card ID = " + result.enrollmentResult.cardId)
                }
                is FidelResult.Error -> {
                    Log.e(FIDEL_DEBUG_TAG, "Error message = " + result.error.message)
                }
            }
        }
    }

    private companion object {
        private const val FIDEL_DEBUG_TAG = "fidel.debug"
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      